




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Reports } from '@/store';
import TondoReports from '@/pages/reports/TondoReports.vue';

@Component({ 
    components: {
        TondoReports
    }
})
export default class ReportsPage extends Vue {
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
    @Reports.Getter('commissionedCabinets') commissionedCabinets;
    @Reports.State('devicesCount') devicesCount;
    @Reports.State('reportsList') reportsList;
    @Global.Mutation('setPageTitle') setPageTitle;

    mounted(){
        this.setPageTitle('Reports');
    }

    get loading(){
        return this.devicesCount !== this.reportsList.length;
    }
}
